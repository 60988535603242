import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import Footer from "./Footer";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";

const javascriptDefault = `
/*
    You can access the params input using the PARAMS constant variable.
*/


let new_value = PARAMS.value

/* 
    Function response is the output response that will be returned by this function.
*/
setFunctionResponse(new_value)


/* 
    The name of the function should be unique and concise.
    Example:
        NumberToFixed
*/
setFunctionName("")

/* 
    Description should be exposit.
    Example:
        Formats a number with a specific number of digits to the right of the decimal.

*/
setFunctionDescription("")

/* 
    Tags help to group functions according to use cases. Some function has 1 or more tags that can be used in multiple use cases.
    Recommended tags:
      'number', 'boolean', 'string', 'string-html-wrappers', 'array', 'date', 'math', 'regex'

*/

setFunctionTags(['array'])

`;

const jsonDefault = JSON.stringify({ value: ["Backender", "is", "awesome"] }, null, 2)

const CodeEditor = ({ id, function_details }) => {
  console.log(function_details)
  let default_code = function_details.script ? function_details.script : javascriptDefault
  let default_params = function_details.sample_params 
                    ? JSON.stringify(function_details.sample_params, null, 2) 
                    : jsonDefault

  const [code, setCode] = useState(default_code);
  const [paramsCode, setParamsCode] = useState(default_params);
  
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState({ success: false, error_message: '', result: {} });
  const [processing, setProcessing] = useState(null);
  const [saving, setSaving] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  const onChangeParams = (action, data) => {
    switch (action) {
      case "code": {
        setParamsCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  
  const handleCompile = async () => {
    setProcessing(true);
    console.log(code)
    console.log(paramsCode)

    let { data } = await axios.post('https://api.backender.io/function/exec', { script: code, params: JSON.parse(paramsCode) })

    if(data.success){
      showSuccessToast('Compiled successfully!')
    }else{
      showErrorToast('Failed to compile!', 2000)
    }
    setOutputDetails(data)
    setProcessing(false)
  };


  const handleSaving = async () => {
    setSaving(true);
    let { data } = await axios.post('https://api.backender.io/function/save', { id, script: code, params: JSON.parse(paramsCode) })
    if(data.success){
      showSuccessToast('Saved successfully!')
    }else{
      showErrorToast(data.message, 2000)
    }
    setSaving(false)
  };

  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-row">
        <div className="px-4 py-2">
            <button
              onClick={handleCompile}
              disabled={!code}
              className={classnames(
                "border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                !code ? "opacity-50" : ""
              )}
            >
              {processing ? "Processing..." : "► Test"}
            </button>
        </div>
        <div className="px-4 py-2">
            <button
              onClick={handleSaving}
              className={classnames(
                "border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                !code ? "opacity-50" : ""
              )}
            >
              {saving ? "Saving..." : " ✓ Save "}
            </button>
        </div>
        <div className="px-4 py-2 ml-5">
          <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
        </div>
      </div>
      <div className="flex flex-row space-x-4 items-start px-4 py-4">
        <div className="flex flex-col w-full h-full justify-start items-end">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            theme={theme.value}
            language={language?.value}
          />
        </div>

        <div className="right-container flex flex-shrink-0 w-[30%] flex-col">
          <CodeEditorWindow
              code={paramsCode}
              onChange={onChangeParams}
              language="json"
              theme={theme.value}
              height="50vh"
            />
          <div className="flex flex-col items-end">
            <OutputWindow outputDetails={outputDetails} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default CodeEditor;
