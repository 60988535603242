import React, { useEffect, useState } from "react";

import "./App.css";

import CodeEditor from "./components/CodeEditor";
import Functions from "./components/Functions";

import axios from "axios";

const search = window.location.search;
const params = new URLSearchParams(search);
const id = params.get('id');

function App() {

  const [loading, setLoading] = useState(true);
  const [functionDetails, setFunctionDetails] = useState(0);


  (async () => {
    if(loading && id){
      let { data } = await axios.get('https://api.backender.io/functions/'+id)
      setFunctionDetails(data.data)
      setLoading(false)
    }
  })()

  if(id == null){
    return <Functions/>
  }

  return (<> {loading ? 'loading..' : <CodeEditor id={id} function_details={functionDetails} />} </>);
}

export default App;
