import React, { useEffect, useState } from "react";

import axios from "axios";
import { classnames } from "../utils/general";

const Functions = () => {
    const [functions, setFunctions] = useState([])
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);

    (async () => {
        if(loading){
            setLoading(false)
            let { data } = await axios.get('https://api.backender.io/functions')
            setFunctions(data.data.filter(x => x.name))
        }
    })()


    const handleCreate = async () => {
        setCreating(true)
        let { data } = await axios.post('https://api.backender.io/functions', {})

        setCreating(false)
        if(data.id){
            let url = "https://manage-functions.backender.io/?id="+data.id;
            window.open(url, '_blank').focus();
        }
    };
    
    const handleClick = (data) => {
        let url = "https://manage-functions.backender.io/?id="+data.id;
        window.open(url, '_blank').focus();
    };

    return (
        <div className="m-5">
            <div className="px-4 py-2 mb-5 ml-3">
                <button
                onClick={handleCreate}
                disabled={creating}
                className={classnames(
                    "border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0"
                )}
                >
                + Create
                </button>
            </div> 
            <span className="font-bold text-xl bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
                Functions
            </span>
            <ul>{functions.map((details)=>{
                return (
                    <li className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2" 
                        onClick={e => { handleClick(details) }}>
                        ✎ {details.name}
                    </li>
                )
            })}</ul>
        </div>
    );
};

export default Functions;
